import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';


export default function Vessels(props) {
  return props.list.length ? (
    <React.Fragment>
      <Accordion.Toggle as={Card.Header} eventKey={props.eventKey}>
        {`${props.label} (${props.list.length})`}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.eventKey}>
        <div className="indent">
          <Table size="sm">
            <tbody>
              {props.list.map((e, i) => (
                <tr key={i}>
                  <td>{e.value}</td>
                  <td>{e.label}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Accordion.Collapse>
    </React.Fragment>
  ) : null;
}
