import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionCollapse from './AccordionCollapse';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { IoIosWarning } from 'react-icons/io';

import TripDetail from './TripDetail';
import API from './API';
import { formatDT } from './Util';
import Vessels from './Vessels';


export default class UserDBContents extends React.Component {
  state = {
    trips: null,
  };

  async componentDidMount() {
    var data = await API.get(`userDBs/${this.props.dbname}/trips`);
    console.log(data);
    this.setState({
      trips: data.list,
      vessels: data.vessels,
    });
  }

  render() {
    if (!this.state.trips) {
      return (<Spinner animation="border" />);
    }

    return (
      <div className="indent">
        <Accordion>
          <Vessels
            list={this.state.vessels}
            label="Vessels"
            eventKey="Vessels"/>

          {this.state.trips.map(e => (
            <Card key={e.tripId} style={{'overflow-x': 'auto'}}>
              <Accordion.Toggle as={Card.Header} eventKey={e.tripId}>
                {`${e.fsClientNumber?"":"Training "}Trip ${formatDT(e.startDate)} - ${e.status}`}
                {e.errorsCount > 0 &&
                  <React.Fragment>
                    {','}<IoIosWarning color="red" size="1.5em"/>
                    {`(${e.errorsCount} error${e.errorsCount>1?'s':''})`}
                  </React.Fragment>
                }
              </Accordion.Toggle>
              <AccordionCollapse eventKey={e.tripId}>
                <TripDetail dbname={this.props.dbname} tripId={e.tripId}/>
              </AccordionCollapse>
            </Card>
          ))}
        </Accordion>
      </div>
    );
  }
}
