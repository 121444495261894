import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionCollapse from './AccordionCollapse';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { IoIosWarning } from 'react-icons/io';

import UserDBContents from './UserDBContents.js';
import API from './API';
import { formatDT } from './Util';
import Vessels from './Vessels';

export function UserDBTitle({userdb}) {
  return (
    <React.Fragment>
      {`userdb-${userdb.dbname.substring(0, 16)}, (${userdb.device}, ${userdb.version || 'No version info'}), ${userdb.userInfo}, ${userdb.lastTripDate ? ("Last Trip: " + formatDT(userdb.lastTripDate)) : "No Trips"}`}
      {userdb.errorsCount > 0 &&
        <React.Fragment>
          {','}<IoIosWarning color="red" size="1.5em"/>
          {`(${userdb.errorsCount} error${userdb.errorsCount>1?'s':''})`}
        </React.Fragment>
      }
      <br/>
      {userdb.permits}
      <br/>
      {userdb.vessels}
    </React.Fragment>
  );
}

export default class ClientUserDBs extends React.Component {
  state = {
    userdbs: null,
  };

  async componentDidMount() {
    var data = await API.get(`clients/${this.props.clientNumber}/userDBs`);
    console.log(data);
    this.setState({userdbs: data.list});
  }

  render() {
    if (!this.state.userdbs) {
      return (<Spinner animation="border" />);
    }

    return (
      <div className="indent">
        <Accordion>
          <Vessels
            list={this.props.vessels}
            label="Vessels"
            eventKey="Vessels"/>

          {this.state.userdbs.map(e => (
            <Card key={e.dbname}>
              <Accordion.Toggle as={Card.Header} eventKey={e.dbname}>
                <UserDBTitle userdb={e}/>
              </Accordion.Toggle>
              <AccordionCollapse eventKey={e.dbname}>
                <UserDBContents dbname={e.dbname}/>
              </AccordionCollapse>
            </Card>
          ))}
        </Accordion>
      </div>
    );
  }
}
