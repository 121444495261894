import React from 'react';
import Autosuggest from 'react-autosuggest';
import './AutoSuggest.css';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';

import API from './API';
import UserDBContents from './UserDBContents.js';
import ClientUserDBs, {UserDBTitle} from './ClientUserDBs';
import ClientList, {ClientTitle} from './ClientList';


export default class UserDBsList extends React.Component {
  state = {
    userdbs: null,
    clients: null,
    suggestions: [],
    value: "",
  };

  async componentDidMount() {
    var data = API.get('userDBs');
    var clients = API.get('clients');
    this.setState({
      userdbs: (await data).list,
      clients: (await clients).list
    });
  }

  string2Hex(tmp) {
    var str = '';
    for(var i = 0; i < tmp.length; i++) {
        str += tmp[i].charCodeAt(0).toString(16);
    }
    return str;
  }

  getSuggestions(value) {
    const matchDBs = (userdb) => {
      // search for multiple words divided by space - all words have to match. e.g search by permit and vessel.
      const values = value.split(' ');
      return values.every((chunk) => {
        return (
          userdb.dbname.startsWith(this.string2Hex(chunk)) || // user "name" translated to db name
          userdb.dbname.startsWith(chunk) ||
          `userdb-${userdb.dbname}`.startsWith(chunk) ||
          ((userdb.userInfo || "").toLocaleLowerCase().indexOf(chunk.toLocaleLowerCase()) >= 0) ||
          ((userdb.vessels || "").toLocaleLowerCase().indexOf(chunk.toLocaleLowerCase()) >= 0) ||
          ((userdb.permits || "").toLocaleLowerCase().indexOf(chunk.toLocaleLowerCase()) >= 0)
        );
      });
    };

    const matchClients = (client) => {
      // search for multiple words divided by space - all words have to match. e.g search by permit and vessel.
      const values = value.split(' ');
      return values.every((chunk) => {
        return (
          ((client.id || "").toLocaleLowerCase().indexOf(chunk.toLocaleLowerCase()) >= 0) ||
          ((client.name || "").toLocaleString().toLocaleLowerCase().indexOf(chunk.toLocaleLowerCase()) >= 0) ||
          ((client.vessels
            .filter(e => e.value) // remove "No Vessel"
            .map(e => `${e.value}: ${e.label}`.toLocaleLowerCase()).join(", ")) // dump into one string
            .indexOf(chunk.toLocaleLowerCase()) >= 0)
        );
      });
    };

    const dbs = this.state.userdbs.filter(matchDBs);
    const clients = this.state.clients.filter(matchClients);
    return dbs.concat(clients);
  }

  getSuggestionValue(e) {
    return e.dbname || e.id;
  }

  renderSuggestion(e) {
    return (
      <Card>
        <Card.Header>
          {e.dbname && <UserDBTitle userdb={e}/>}
          {e.id && <ClientTitle {...e}/>}
        </Card.Header>
      </Card>
    );
  }

  onChange = (event, { newValue, method }) => {
    if (method === "click") {
      this.setState({
        suggestions: [],
      });
    }
    else {
      this.setState({
        selectedItem: null,
        value: newValue
      });
    }
  };
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({
      selectedItem: suggestion,
    });
  }
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    if (!this.state.userdbs || !this.state.clients) {
      return (<Spinner animation="border" />);
    }

    const inputProps = {
      placeholder: "Search...",
      value: this.state.value,
      onChange: this.onChange,
      className: "form-control",
      autoFocus: true,
    };

    return (
      <>
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
        />
        {this.state.selectedItem &&
          <>
            {this.renderSuggestion(this.state.selectedItem)}

            {this.state.selectedItem.dbname && <UserDBContents key={this.state.selectedItem.dbname} dbname={this.state.selectedItem.dbname}/>}

            {this.state.selectedItem.clientNumber &&
              <>
                <div className="indent">
                {this.state.selectedItem.contact && <>
                  <small className="text-secondary">{this.state.selectedItem.contact?.firstName} {this.state.selectedItem.contact?.surname}, {this.state.selectedItem.contact?.email}, {this.state.selectedItem.contact?.phone}</small>
                  <br/>
                </>}
                  <small className="text-secondary">{this.state.selectedItem.notes}</small>
                </div>
                <ClientUserDBs
                  clientNumber={this.state.selectedItem.clientNumber}
                  vessels={this.state.selectedItem.vessels}
                />
              </>
            }

            <hr/>
          </>
        }
        {!this.state.value && !this.state.selectedItem && <ClientList clients={this.state.clients}/>}
      </>
    );
  }
}
