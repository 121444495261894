import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

function decodeToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export default class Auth extends React.Component {
  state = {authed: false};

  constructor(props) {
    super(props);
    this.state = {'authed': false};
  }

  responseGoogle(response) {
    if (response.credential) {
      var tokenData = decodeToken(response.credential);
      let expiresAt = JSON.stringify(new Date(tokenData.exp * 1000).getTime());
      localStorage.setItem('idToken', response.credential);
      localStorage.setItem('idToken_expires_at', expiresAt);
      this.setState({authed: true});
    }
  }
  responseGoogleFail(response) {
    console.log(response);
  }

  render() {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {this.state.authed ? this.props.children : (
          <div id='login'>
            <GoogleLogin
              auto_select
              useOneTap
              onSuccess={this.responseGoogle.bind(this)}
              onError={this.responseGoogleFail.bind(this)}
            />
          </div>
        )}
      </GoogleOAuthProvider>
    );
  }
}
