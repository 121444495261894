import axios from 'axios';

class API {
  async get(resource, params={}) {
    const jwt_expiry = parseInt(localStorage.getItem('idToken_expires_at'));
    if (jwt_expiry < new Date().getTime()) {
      localStorage.removeItem('idToken');
      localStorage.removeItem('idToken_expires_at');
      window.location.reload();
      return null; // the reload doesn't happen immediately
    }
    const token = localStorage.getItem('idToken');
    const url = process.env.REACT_APP_API_URL + resource;
    var response = await axios.get(url, { headers: {"Authorization" : token}, params: params, });
    return response.data;
  }
}

export default new API();
