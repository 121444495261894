import React from 'react';
import './App.css';

import Auth from './Auth';
import Search from './Search';

function App() {
  return (
    <div className="App">
      <Auth>
        <Search/>
      </Auth>
    </div>
  );
}

export default App;
