import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionCollapse from './AccordionCollapse';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { IoIosWarning } from 'react-icons/io';

import API from './API';
import { formatDT } from './Util';


function LabelValueRenderer(props) {
  if (Array.isArray(props.value)) {
    return (<ExpandableList
      list={props.value}
      label={props.label}
      eventKey={props.label}/>);
  }
  else {
    return (
      <tr>
        <td>{props.label}</td>
        <td><ValueRenderer value={props.value}/></td>
      </tr>
    );
  }
}

function DocumentRenderer(props) {
  return (
    <React.Fragment>
      {Object.keys(props.document).map(k => (
        <LabelValueRenderer key={k} label={k} value={props.document[k]}/>
      ))}
    </React.Fragment>
  );
}

function ExpandableList(props) {
  return (
    <tr>
      <td colSpan="2">
        <Accordion>
          <Accordion.Toggle as={Card.Header} eventKey={props.eventKey}>
            {`${props.label} (${props.list.length})`}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={props.eventKey}>
            <React.Fragment>
              {props.list.map((e, i) => (
                <div className="indent" key={i}>
                  {typeof(e) == 'object' ?
                    <div className="indent">
                      {`${props.label} (${i})`}
                      <Table size="sm">
                        <tbody>
                          <DocumentRenderer document={e}/>
                        </tbody>
                      </Table>
                    </div>
                  :
                    <ValueRenderer value={e}/>
                }
                </div>
              ))}
            </React.Fragment>
          </Accordion.Collapse>
        </Accordion>
      </td>
    </tr>
  );
}

function FishServeEvent(props) {
  const fsEvent = props.fsEvent;
  console.log(fsEvent);
  if (fsEvent) {
    return (
      <tr>
        <td colSpan="2">
          <Accordion>
            <Accordion.Toggle as={Card.Header} eventKey={fsEvent}>
              {`FishServe Status${props.label || ''}: ${fsEvent.status}`}
              {fsEvent.status === 'DataError' && <IoIosWarning color="red" size="1.5em"/>}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={fsEvent}>
              <div className="indent">
                <Table size="sm">
                  <tbody>
                    <DocumentRenderer document={fsEvent}/>
                  </tbody>
                </Table>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </td>
      </tr>
    );
  }
  else {
    return null;
  }
}

function EventListFlat(props) {
  return (
    <React.Fragment>
      {props.list.map((e, i) => (
        <tr key={i}>
          <td colSpan="2">
            <Accordion.Toggle as={Card.Header} eventKey={props.keyFunc(e, i)}>
              {props.labelFunc(e, i)}
              {(props.fsEvents[`fse-${e.eventId}`] || {}).status === "DataError" &&
                <IoIosWarning color="red" size="1.5em"/>}
            </Accordion.Toggle>
            <AccordionCollapse eventKey={props.keyFunc(e, i)}>
              <div className="indent">
                <Table size="sm">
                  <tbody>
                    <DocumentRenderer document={e}/>
                    <FishServeEvent fsEvent={props.fsEvents[`fse-${e.eventId}`]}/>
                  </tbody>
                </Table>
              </div>
            </AccordionCollapse>
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
}

function ValueRenderer(props) {
  const value = props.value;
  if (!value) {
    return 'N/A';
  }
  else if (typeof value == 'string') {
    return value;
  }
  else if (value.systemLocation && value.systemDateTime) {
    return (
      <div>
        {formatDT(value.systemDateTime)}
        <br/>
        <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${value.systemLocation.latitude},${value.systemLocation.longitude}`}>
          {`${value.systemLocation.latitude}, ${value.systemLocation.longitude} (±${value.systemLocation.accuracy ? value.systemLocation.accuracy.toFixed(2) : '?'})`}
        </a>
      </div>
    );
  }
  else if (value.longitude) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${value.latitude},${value.longitude}`}>
        {`${value.latitude}, ${value.longitude}`}
      </a>
    );
  }

  return JSON.stringify(value);
}


export default class TripDetail extends React.Component {
  state = {
    tripDetail: null,
  };

  async componentDidMount() {
    var data = await API.get(`userDBs/${this.props.dbname}/trips/${this.props.tripId}`);
    console.log(data);
    var newState = {
      tripDetail: data,
      events: data.events || [],
      nfpsEvents: data.nfpsEvents || [],
      disposalEvents: data.disposalEvents || [],
      landingEvents: data.landingEvents || [],
      fsEvents: data.fsEvents || [],
    };
    delete newState.tripDetail._rev;
    delete newState.tripDetail.docType;
    delete newState.tripDetail.createdTimestamp;

    delete newState.tripDetail.events;
    delete newState.tripDetail.nfpsEvents;
    delete newState.tripDetail.disposalEvents;
    delete newState.tripDetail.landingEvents;
    delete newState.tripDetail.fsEvents;

    this.setState(newState);
  }

  render() {
    if (!this.state.tripDetail) {
      return (<Spinner animation="border" />);
    }

    return (
      <div className="indent">
        <Accordion>
          <Table size="sm">
            <tbody>
              <DocumentRenderer document={this.state.tripDetail}/>
              <FishServeEvent label=" Trip Start" fsEvent={this.state.fsEvents[`fse-S${this.state.tripDetail._id}`]}/>
              <EventListFlat
                list={this.state.events}
                labelFunc={e => `Fishing Event ${(e.startLocation && formatDT(e.startLocation.systemDateTime)) || ''} - ${e.status}`}
                keyFunc={e => e.eventId}
                fsEvents={this.state.fsEvents}
              />
              <EventListFlat
                list={this.state.nfpsEvents}
                labelFunc={e => `NFPS Event ${(e.nfpsLocation && formatDT(e.nfpsLocation.systemDateTime)) || ''} - ${e.status}`}
                keyFunc={e => e.eventId}
                fsEvents={this.state.fsEvents}
              />
              <EventListFlat
                list={this.state.disposalEvents}
                labelFunc={e => `Disposal Event ${(e.disposalLocation && formatDT(e.disposalLocation.systemDateTime)) || ''} - ${e.status}`}
                keyFunc={e => e.eventId}
                fsEvents={this.state.fsEvents}
              />
              <EventListFlat
                list={this.state.landingEvents}
                labelFunc={e => `Landing Event ${(e.landingLocation && formatDT(e.landingLocation.systemDateTime)) || ''} - ${e.status}`}
                keyFunc={e => e.eventId}
                fsEvents={this.state.fsEvents}
              />
              <FishServeEvent label=" Trip End" fsEvent={this.state.fsEvents[`fse-E${this.state.tripDetail._id}`]}/>
            </tbody>
          </Table>
        </Accordion>
      </div>
    );
  }
}
