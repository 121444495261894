import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionCollapse from './AccordionCollapse';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import ClientUserDBs from './ClientUserDBs.js';


export function ClientTitle(props) {
  return (
    <>{props.clientNumber} - {props.name} {(!props.status || props.status === "active") ? "" : `(${props.status})`}</>
  );
}

export default class ClientList extends React.Component {
  render() {
    if (!this.props.clients) {
      return (<Spinner animation="border" />);
    }

    return (
      <Accordion>
        {this.props.clients.map(e => (
          <Card key={e.id}>
            <Accordion.Toggle as={Card.Header} eventKey={e.id}>
              <ClientTitle {...e}/>
            </Accordion.Toggle>
            <AccordionCollapse eventKey={e.id}>
              <>
                <div className="indent">
                  {e.contact && <>
                    <small className="text-secondary">{e.contact?.firstName} {e.contact?.surname}, {e.contact?.email}, {e.contact?.phone}</small>
                    <br/>
                  </>}
                  <small className="text-secondary">{e.notes}</small>
                </div>
                <ClientUserDBs
                  clientNumber={e.clientNumber}
                  vessels={e.vessels}
                />
              </>
            </AccordionCollapse>
          </Card>
        ))}
      </Accordion>
    );
  }
}
